import Image from 'next/image'

import Illustration from '/public/illustrations/representative.svg'

import { Button, Container, Icon, Title } from '@/components'

import { REPRESENTATIVE_URL } from '@/constants/links'

import styles from './Representative.module.scss'

export const Representative = () => {
  return (
    <section className={styles.representative}>
      <Container classname={styles.representative__wrapper}>
        <Image src={Illustration} alt="ilustraçãot" width="307" height="219" />

        <div>
          <Title
            customClass={styles.representative__title}
            text={
              <>
                Seja um representante <b>Equifax BoaVista</b>
              </>
            }
          />

          <div className={styles.representative__content}>
            <p className={styles.representative__text}>
              Comercialize nossas soluções e tenha diversos benefícios para o
              seu negócio.
            </p>

            <Button
              tagName="a"
              rel="noreferrer"
              target="_blank"
              id="representative-becomeRepresentative-button"
              className={styles.representative__button}
              href={REPRESENTATIVE_URL}
            >
              <Icon size="small" color="white" name="equifax-representative" />
              Quero ser representante
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
