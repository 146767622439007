export const ADVANTAGES = [
  {
    icon: 'money-coin',
    text: 'Consultas unitárias mais baratas e desconto progressivo'
  },
  {
    icon: 'equifax-hand-check',
    text: 'Sem fidelidade'
  },
  {
    icon: 'equifax-user-check',
    text: 'Oferece serviços de Negativação e Notificação'
  },
  {
    icon: 'equifax-bill',
    text: 'Pagamento através de boleto bancário'
  },
  {
    icon: 'equifax-calendar-time',
    text: 'Primeiro pagamento entre 30 e 60 dias após a contratação'
  },
  {
    icon: 'equifax-company-size',
    text: 'Planos para empresas de todos os tamanhos'
  }
]
