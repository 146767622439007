'use client'

import { useState } from 'react'

import { Badge, Button, Icon, If } from '@/components'

import styles from './Plan.module.scss'

export const Plan = ({ plan, customClass }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className={`${styles.plan} ${customClass}`}>
      <div>
        <If condition={plan.badge_text}>
          <Badge
            round
            color="red"
            icon="equifax-stars"
            text={plan.badge_text}
            customClass={styles.plan__bestSelling}
          />
        </If>

        <div className={styles.plan__icon}>
          <Icon size="xs" color="primary" name="equifax-finance-chip" />
          {plan.label}
        </div>

        <p className={styles.plan__highlightText}>{plan.highlight_text}</p>
      </div>

      <Button
        tagName="a"
        rel="noreferrer"
        href={plan.url}
        isLoading={isLoading}
        onClick={() => setIsLoading(true)}
      >
        Quero recarregar
      </Button>
    </div>
  )
}
