import Image from 'next/image'

import useClickWithScroll from '@equifax-ui/hooks/useClickWithScroll'

import { Container, Title, Tag, Button, Caption } from '@/components'

import Woman from '/public/images/interconnect-box.webp'

import { Advantage } from './components'

import { ADVANTAGES } from './constants'

import * as styles from './Interconnect.module.scss'

export const Interconnect = () => {
  const { onClickItem } = useClickWithScroll()

  return (
    <section className={styles.interconnect}>
      <Container>
        <Tag text="Lançamento" bgColor="white" />

        <Title
          customClass={styles.interconnect__title}
          text={
            <>
              <b>InterConnect Box</b>: A solução ideal para{' '}
              <b>pequenas e médias empresas</b> que buscam aprovação de crédito
              rápida, segura e econômica.
            </>
          }
        />

        <div className={styles.interconnect__wrapper}>
          <div className={styles.interconnect__image}>
            <Image
              src={Woman}
              alt="homem utilizando o InterConnect Box em seu notebook"
              style={{
                width: '100%',
                height: 'auto'
              }}
            />
          </div>

          <div>
            {ADVANTAGES.map(({ icon, title, text }, index) => (
              <Advantage
                key={`interconnect-advantage-${index}`}
                icon={icon}
                title={title}
                text={text}
              />
            ))}

            <Caption
              icon="equifax-info"
              text="Serviço disponível apenas no plano Pós-pago"
            />

            <Button
              variant="primary"
              id="interconnectBox-wantToHire-button"
              className={styles.interconnect__button}
              onClick={() => onClickItem('proposal', 4)}
            >
              Quero contratar
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
