import Image from 'next/image'

import classnames from 'classnames'

import {
  Button,
  Container,
  HiringSteps,
  If,
  Subtitle,
  Tag,
  Title
} from '@/components'

import { usePlans } from '@/hooks/usePlans'

import { PRE_PAID_PLANS } from '@/constants/plans'

import { Plan, PlansHeader } from './components'

import { PLANS_DATA } from './constants'

import styles from './Plans.module.scss'

export const Plans = ({ planType }) => {
  const { postpaidPlans } = usePlans()

  const planInfos = PLANS_DATA[planType]
  const plans = planType === 'postpaid' ? postpaidPlans : PRE_PAID_PLANS

  return (
    <>
      <section className={styles.plans}>
        <Container>
          <Tag text={planInfos.tag_text} />

          {planType === 'postpaid' && (
            <Title customClass={styles.plans__title} text={planInfos.title} />
          )}

          <div
            className={classnames(styles.plans__wrapper, {
              [styles[`plans__wrapper--reverse`]]: planType === 'prepaid'
            })}
          >
            <div>
              <Subtitle text={planInfos.subtitle} />

              <HiringSteps steps={planInfos.hiring_steps} />

              <div className={`${styles.plans__image}`}>
                <Image
                  {...planInfos.image}
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </div>
            </div>

            <div>
              <div>
                <PlansHeader icon={planInfos.icon} title={planInfos.tag_text} />

                <div className={styles.plans__list}>
                  {plans.map((plan, index) => (
                    <div key={`${plan.type}-${plan.id}`}>
                      <Plan plan={plan} />

                      <If condition={index + 1 !== plans.length}>
                        <hr className={styles.plans__divisor} />
                      </If>
                    </div>
                  ))}
                </div>
              </div>

              <Button
                tagName="a"
                rel="noreferrer"
                variant="secondary"
                href={planInfos.redirect_url}
                className={styles.plans__redirectButton}
                id={`${planType}Section-redirectToLP-button`}
              >
                Saiba mais
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
